
export default {
  props: {
    link: {
      type: Object,
      required: true,
    },

    bgWhite: {
      type: Boolean,
      default: false,
    },

    withIcon: {
      type: Boolean,
      default: true,
    },

    isFaq: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isHover: false,
    }
  },

  computed: {
    goToUrl() {
      if (this.link.type === 'website') {
        return this.link.link
      }

      return this.link.video
    },
  },
}
